import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {
  fetchDailyPulseByClient,
  fetchDailyPulseByCampaign,
  fetchDailyPulseByChannel
} from "@src/api";
import {useAuth} from "@src/context";
import {formatDateToYMD, getCurrentWeekDates} from "@src/utils/dateUtils";

const DailyPulseContext = React.createContext(null);

export const useDailyPulse = () => (useContext(DailyPulseContext));

export const DailyPulseProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const { isAuthenticated } = useAuth();
  const [monday, sunday] = getCurrentWeekDates();
  const [startDate, setStartDate] = useState(monday);
  const [endDate, setEndDate] = useState(sunday);
  const baseFilters = useMemo(() => ({
    date_after: formatDateToYMD(startDate),
    date_before: formatDateToYMD(endDate),
  }), [startDate, endDate]);
  const [tree, setTree] = useState([]);
  console.log(tree)


  useEffect(() => {
    if (isAuthenticated) {
      fetchDailyPulseByClient(baseFilters).then((resp) => {
        setIsLoading(false);
        setTree(resp.data);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsError(true);
      })
    }
  }, [isAuthenticated, baseFilters, setTree, setIsLoading]);

  useEffect(() => {
    const arr = [];
    tree.forEach((client) => {
      arr.push(client);
      (client.channels || []).forEach((channel) => {
        arr.push(channel);
        (channel.campaigns || []).forEach((campaign) => {
          arr.push(campaign);
        })
      });
    });
    setData(arr);
  }, [tree, setData]);

  const handleClientClick = (row) => {
    const client = tree.find((o) => o.uuid === row.uuid);
    const channels = client.channels || [];
    if (channels.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.uuid === row.uuid) {
          return {
            ...item,
            channels: []
          }
        }
        return item;
      }));
    } else {
      fetchDailyPulseByChannel({
        client_id: row.client_id,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.uuid === row.uuid) {
            return {
              ...item,
              channels: resp.data
            }
          }
          return item;
        }));
      });
    }
  }

  const handleChannelClick = (row) => {
    const client = tree.find((o) => o.client_id === row.client_id);
    const channels = client.channels || [];
    const channel = channels.find((o) => o.uuid === row.uuid);
    const campaigns = channel.campaigns || [];
    if (campaigns.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.client === row.client) {
          return {
            ...item,
            channels: item.channels.map((ch) => {
              if (ch.uuid === row.uuid) {
                return {
                  ...ch,
                  campaigns: []
                }
              }
              return ch;
            })
          }
        }
        return item;
      }));
    } else {
      fetchDailyPulseByCampaign({
        client_id: row.client_id,
        channel_id: row.channel_id,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.client === row.client) {
            return {
              ...item,
              channels: item.channels.map((ch) => {
                if (ch.uuid === row.uuid) {
                  return {
                    ...ch,
                    campaigns: resp.data
                  }
                }
                return ch;
              })
            }
          }
          return item;
        }));
      });
    }
  }

  const loadData = (row) => {
    if (row.client__name) {
      handleClientClick(row);
    } else if (row.channel__name) {
      handleChannelClick(row);
    }
  };

  const setDateRange = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, [setStartDate, setEndDate])

  const value = useMemo(() => ({
    isLoading,
    isError,
    data,
    loadData,
    startDate,
    endDate,
    setDateRange
  }), [
    isLoading,
    isError,
    data,
    loadData,
    startDate,
    endDate,
    setDateRange
  ]);

  return (
    <DailyPulseContext.Provider value={value}>
      {children}
    </DailyPulseContext.Provider>
  );
};
