import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {
  fetchClientPerformanceByClient,
  fetchClientPerformanceByChannel,
  fetchClientPerformanceByCampaign,
  fetchClientPerformanceByAdSet,
  fetchClientPerformanceByAd,
} from "@src/api";
import {useAuth} from "@src/context";
import {formatDateToYMD, getCurrentWeekDates} from "@src/utils/dateUtils";

const ClientPerformanceContext = React.createContext(null);

export const useClientPerformance = () => (useContext(ClientPerformanceContext));

export const ClientPerformanceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const { isAuthenticated } = useAuth();
  const [monday, sunday] = getCurrentWeekDates();
  const [startDate, setStartDate] = useState(monday);
  const [endDate, setEndDate] = useState(sunday);
  const baseFilters = useMemo(() => ({
    date_after: formatDateToYMD(startDate),
    date_before: formatDateToYMD(endDate),
  }), [startDate, endDate]);
  const [tree, setTree] = useState([]);


  useEffect(() => {
    if (isAuthenticated) {
      fetchClientPerformanceByClient(baseFilters).then((resp) => {
        setData(resp.data);
        setIsLoading(false);
        setTree(resp.data);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsError(true);
      })
    }
  }, [isAuthenticated, baseFilters]);

  useEffect(() => {
    const arr = [];
    tree.forEach((client) => {
      arr.push(client);
      (client.channels || []).forEach((channel) => {
        arr.push(channel);
        (channel.campaigns || []).forEach((campaign) => {
          arr.push(campaign);
          (campaign.adSets || []).forEach((adSet) => {
            arr.push(adSet);
            (adSet.ads || []).forEach((ad) => {
              arr.push(ad);
            })
          })
        });
      });
    });
    setData(arr);
  }, [tree, setData]);

  const handleClientClick = (row) => {
    const client = tree.find((o) => o.uuid === row.uuid);
    const channels = client.channels || [];
    if (channels.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.uuid === row.uuid) {
          return {
            ...item,
            channels: []
          }
        }
        return item;
      }));
    } else {
      fetchClientPerformanceByChannel({
        client_id: row.client_id,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.uuid === row.uuid) {
            return {
              ...item,
              channels: resp.data
            }
          }
          return item;
        }));
      });
    }
  }

  const handleChannelClick = (row) => {
    const client = tree.find((o) => o.client === row.client);
    const channels = client.channels || [];
    const channel = channels.find((o) => o.uuid === row.uuid);
    const campaigns = channel.campaigns || [];
    if (campaigns.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.client === row.client) {
          return {
            ...item,
            channels: item.channels.map((ch) => {
              if (ch.uuid === row.uuid) {
                return {
                  ...ch,
                  campaigns: []
                }
              }
              return ch;
            })
          }
        }
        return item;
      }));
    } else {
      fetchClientPerformanceByCampaign({
        client_id: row.client_id,
        channel_id: row.channel_id,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.client === row.client) {
            return {
              ...item,
              channels: item.channels.map((ch) => {
                if (ch.uuid === row.uuid) {
                  return {
                    ...ch,
                    campaigns: resp.data
                  }
                }
                return ch;
              })
            }
          }
          return item;
        }));
      });
    }
  }

  const handleCampaignClick = (row) => {
    const client = tree.find((o) => o.client_id === row.client_id);
    const channels = client.channels || [];
    const channel = channels.find((o) => o.channel_id === row.channel_id);
    const campaigns = channel.campaigns || [];
    const campaign = campaigns.find((o) => o.uuid === row.uuid);
    const adSets = campaign.adSets || [];
    if (adSets.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.client === row.client) {
          return {
            ...item,
            channels: item.channels.map((ch) => {
              if (ch.channel_id === row.channel_id) {
                return {
                  ...ch,
                  campaigns: ch.campaigns.map((c) => {
                    if (c.uuid === row.uuid) {
                      return {
                        ...c,
                        adSets: []
                      }
                    }
                    return c;
                  })
                }
              }
              return ch;
            })
          }
        }
        return item;
      }));
    } else {
      fetchClientPerformanceByAdSet({
        client_id: row.client_id,
        channel_id: row.channel_id,
        campaign: row.campaign,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.client === row.client) {
            return {
              ...item,
              channels: item.channels.map((ch) => {
                if (ch.channel_id === row.channel_id) {
                  return {
                    ...ch,
                    campaigns: ch.campaigns.map((c) => {
                      if (c.uuid === row.uuid) {
                        return {
                          ...c,
                          adSets: resp.data
                        }
                      }
                      return c;
                    })
                  }
                }
                return ch;
              })
            }
          }
          return item;
        }));
      });
    }
  }

  const handleAdSetClick = (row) => {
    const client = tree.find((o) => o.client === row.client);
    const channels = client.channels || [];
    const channel = channels.find((o) => o.channel_id === row.channel_id);
    const campaigns = channel.campaigns || [];
    const campaign = campaigns.find((o) => o.campaign === row.campaign_name);
    const adSet = (campaign.adSets || []).find((o) => o.uuid === row.uuid);
    const ads = adSet.ads || [];
    if (ads.length) {
      setTree((prevState) => prevState.map((item) => {
        if (item.client_id === row.client_id) {
          return {
            ...item,
            channels: item.channels.map((ch) => {
              if (ch.channel_id === row.channel_id) {
                return {
                  ...ch,
                  campaigns: ch.campaigns.map((c) => {
                    return {
                      ...c,
                      adSets: c.adSets.map((as) => {
                        if (as.uuid === row.uuid) {
                          return {
                            ...as,
                            ads: []
                          }
                        }
                        return as;
                      })
                    }
                  })
                }
              }
              return ch;
            })
          }
        }
        return item;
      }));
    } else {
      fetchClientPerformanceByAd({
        client_id: row.client_id,
        channel_id: row.channel_id,
        campaign: row.campaign_name,
        ad_set: row.ad_set_name,
        ...baseFilters
      }).then((resp) => {
        setTree((prevState) => prevState.map((item) => {
          if (item.client_id === row.client_id) {
            return {
              ...item,
              channels: item.channels.map((ch) => {
                if (ch.channel_id === row.channel_id) {
                  return {
                    ...ch,
                    campaigns: ch.campaigns.map((c) => {
                        return {
                          ...c,
                          adSets: (c.adSets || []).map((as) => {
                            if (as.uuid === row.uuid) {
                              return {
                                ...as,
                                ads: resp.data
                              }
                            }
                            return as;
                          })
                        }
                    })
                  }
                }
                return ch;
              })
            }
          }
          return item;
        }));
      });
    }
  }

  const loadData = (row) => {
    if (row.client__name) {
      handleClientClick(row);
    } else if (row.channel__name) {
      handleChannelClick(row);
    } else if (row.ad_set) {
      handleAdSetClick(row);
    } else if (row.campaign) {
      handleCampaignClick(row);
    } else {
      return null;
    }
  };

  const setDateRange = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, [setStartDate, setEndDate])

  const value = useMemo(() => ({
    isLoading,
    isError,
    data,
    loadData,
    startDate,
    endDate,
    setDateRange,
  }), [
    isLoading,
    isError,
    loadData,
    startDate,
    endDate,
    setDateRange
  ]);

  return (
    <ClientPerformanceContext.Provider value={value}>
      {children}
    </ClientPerformanceContext.Provider>
  );
};
